import React from "react";
import { formatNumber } from '../../Utils'
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const SubtotalItem = ({ item, t }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language.split('-')[0];

    return (
        <div>
            <div style={{ paddingBottom: "10px" }}>
                <h5 className='mb-0'>{currentLanguage === 'en' ? item.nameEn : item.name}</h5>
                <span>{currentLanguage === 'en' ? item.descriptionEn : item.description}</span>
            </div>
            {
                item.laundry && item.laundry.noLaundrySelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.noLaundry')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        0.00 CHF
                    </span>
                </div>
            }
            {
                item.laundry && item.laundry.washingAndIroningSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {item.laundry.cleaningAndRefreshing ? t('cart.options.cleanAndRefresh') : t('cart.options.washAndIron')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.laundry.washingAndIroningPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.laundry && item.laundry.washingAndIroningSpeedySelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.washAndIronExpress')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.laundry.washingAndIroningSpeedyPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.tailoring && item.tailoring.buttonSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.button')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.tailoring.buttonQuantity} x ${formatNumber(item.tailoring.buttonPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.tailoring && item.tailoring.hemSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.hem')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.tailoring.hemQuantity} x ${formatNumber(item.tailoring.hemPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.tailoring && item.tailoring.zipperSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.zipper')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.tailoring.zipperQuantity} x ${formatNumber(item.tailoring.zipperPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.delivery && item.delivery.packedSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.folded')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.delivery.packedPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.delivery && item.delivery.hangerSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.onHanger')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.delivery.hangerPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.additional && item.additional.longSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.withLong')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.additional.longPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.additional && item.additional.decorationSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.withDeco')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.additional.decorationPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.additional && item.additional.rufflesSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.withRuffles')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.additional.rufflesPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.additional && item.additional.sequinedSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.withSequins')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.additional.sequinedPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.additional && item.additional.impregnateSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.withImpregnate')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.additional.impregnaatePrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.leather && item.leather.napaSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.napa')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.leather.napaPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.leather && item.leather.velourSelected &&
                <div className="subtotal-item">
                    <span className="item-name">
                        {t('cart.options.velour')}
                    </span>
                    <span className="spacer" />
                    <span id="item-price">
                        {`${item.itemQuantity} x ${formatNumber(item.leather.velourPrice)}`} CHF
                    </span>
                </div>
            }
            {
                item.giftCard &&
                <div className="subtotal-item">
                </div>
            }
            <div>
                <span>
                    {t('cart.quantity.label')}: {item.itemQuantity}
                </span>
                <span className="pull-right">
                    {t('cart.subtotal')}: {formatNumber(item.sumPrice)} CHF
                </span>
            </div>
            <hr />
        </div>
    );
};

export default withTranslation()(SubtotalItem);
